import React, { useState, useEffect, Suspense } from "react";

import { useLocation } from "react-router-dom";
import { DndProvider } from "react-dnd";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import MultiBackend from "react-dnd-multi-backend";

import SuspenseLoader from "components/shared/SuspenseLoader";
import Navbar from "views/layout/Navbar";
import Footer from "views/layout/Footer";
import Headers from "./Headers";
import RoutesAll from "./RoutesAll";

import useRedirects from "./useRedirects";
import useTracking from "./useTracking";

export default function AppRouter() {
  const location = useLocation();

  useRedirects();
  useTracking();

  const [pageTitle, setPageTitle] = useState("Jayaho");

  function getPageTitle() {
    let pathname = location.pathname;
    let pathnameSplit = pathname.split("/");

    if (pathnameSplit[1] === "info" && pathnameSplit[2] === "course")
      return "Jayaho | Course";
    if (pathnameSplit[1] === "course" && pathnameSplit[2] === "learn")
      return "Jayaho | Learn";
    if (pathnameSplit[1] === "user" && pathnameSplit[2] === "reset-pass")
      return "Jayaho | Reset Password";

    switch (pathname) {
      case "/home":
        return "Jayaho | Home";
      case "/offerings":
        return "Jayaho | Offerings";
      case "/courses":
        return "Jayaho | Courses";
      case "/user/profile":
        return "Jayaho | Profile";
      case "/user/courses":
        return "Jayaho | My Courses";
      case "/terms-and-conditions":
        return "Jayaho | Terms & Conditions";
      default:
        return "Jayaho";
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageTitle(getPageTitle());
  }, [location]);

  return (
    <React.Fragment>
      <Navbar />

      <Suspense fallback={<SuspenseLoader />}>
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <Headers pageTitle={pageTitle} />
          <RoutesAll />
        </DndProvider>
      </Suspense>

      {pageTitle !== "Jayaho | Learn" &&
        pageTitle !== "Jayaho | Reset Password" && <Footer />}
    </React.Fragment>
  );
}
