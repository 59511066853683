const config = {
  googleClientId:
    "106709358913-2fbf7gct5q6i96g3tr0u4cvs4noo55pb.apps.googleusercontent.com",
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  razorpayKey: process.env.REACT_APP_RAZORPAY_KEY,
  gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
  env: process.env.REACT_APP_ENV,
  gaMeasurementId: "G-M1V7XTYTJ9",
};

export default config;
