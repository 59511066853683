import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";

import App from "./index/App";
import config from "config/config";

ReactGA.initialize(config.gaMeasurementId);
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
