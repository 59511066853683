import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import ViewHackRegistrations from "views/ViewHackRegistrations";

const Home = lazy(() => import("views/HomePage"));
const FutureSkillsCourses = lazy(() => import("views/Courses/FutureSkills"));
const Courses = lazy(() => import("views/Courses/Courses"));
const ForSchools = lazy(() => import("views/ForSchools"));
const Offerings = lazy(() => import("views/Offerings"));

const TermsAndConditions = lazy(() =>
  import("views/layout/Terms&Conditions/termsAndcond")
);
const Disclaimer = lazy(() => import("views/layout/Disclaimer/disclaimer"));
const RefundPolicy = lazy(() =>
  import("views/layout/RefundPolicy/RefundPolicy")
);
const PrivacyPolicy = lazy(() =>
  import("views/layout/PrivacyPolicy/PrivacyPolicy")
);
const BundleAssessment = lazy(() =>
  import("views/Bundle-Assessment/Mcq/Container")
);
const BundleAssessmentResult = lazy(() =>
  import("views/Bundle-Assessment/Result/Result")
);
const KnowMore = lazy(() => import("views/CourseInfo"));

export default function RoutesMisc() {
  return (
    <Routes>
      <Route path="/home" element={<Home />} />

      {/* <Route path="/register" element={<OurCourses isModalOpen />} /> */}
      {/* <Route path="/registrations" element={<ViewHackRegistrations />} /> */}

      <Route path="courses" element={<Courses />} />
      <Route path="future-skills" element={<FutureSkillsCourses />} />
      <Route path="schools" exact element={<ForSchools />} />
      <Route path="offerings" element={<Offerings />} />

      <Route path="info/course/:courseId" element={<KnowMore />} />

      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="disclaimer" element={<Disclaimer />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="refund-policy" element={<RefundPolicy />} />

      <Route
        path="/assessment/:bundleId"
        element={
          localStorage.getItem("authToken") ? (
            <BundleAssessment />
          ) : (
            <Navigate to="/ " />
          )
        }
      />
      <Route
        path="/assessment/result/:assessmentId"
        element={
          localStorage.getItem("authToken") ? (
            <BundleAssessmentResult />
          ) : (
            <Navigate to="/ " />
          )
        }
      />

      <Route path="/" element={<Navigate to="/home" />} />
    </Routes>
  );
}
